<template>
	<ion-header :translucent="true">
		<ion-toolbar class="ion-no-border">
			<ion-icon v-if="showLogo" :src="require(`@/assets/app_logo.svg`)" size="large" slot="start"></ion-icon>
			<ion-label color="dark">{{ title }}</ion-label>
			<ion-button fill="clear" size="small" :slot="!showLogo ? 'start' : 'end'" v-if="canGoHome" color="primary" @click="$emit('pressedGoHome')">
				<ion-icon slot="icon-only" :icon="home" size="large"></ion-icon>
			</ion-button>
			<ion-button fill="clear" size="small" :slot="!showLogo ? 'start' : 'end'" v-if="canGoBack" color="primary" @click="$emit('pressedGoBack')">
				<ion-icon slot="icon-only" :icon="arrowBack" size="large"></ion-icon>
			</ion-button>
		</ion-toolbar>
	</ion-header>
</template>

<style scoped>
	ion-label {
		font-size: min(5vh,5vw);
		text-align: left;
		font-weight: bold;
		color: var(--ion-color-dark);
		margin-left: .25em;
	}
	ion-icon {
		margin-left: .25em;
	}
	ion-button {
		--padding-start: 0px;
		--padding-end: 0px;
	}
	ion-toolbar {
		--min-height: 0px;
	}
</style>

<script>
import { IonButton, IonHeader, IonIcon, IonLabel, IonToolbar } from '@ionic/vue';
import { arrowBack, home } from "ionicons/icons";
import { defineComponent } from "vue";

	export default defineComponent({
		props: {
			title: { type: String, default: "AppName" },
			showLogo: { type: Boolean, default: true },
			canGoHome: { type: Boolean, default: false },
			canGoBack: { type: Boolean, default: false },
		},
		components: {
			IonHeader,
			IonToolbar,
			IonIcon,
			IonLabel,
			IonButton,
		},
		emits: [ "pressedGoHome", "pressedGoBack" ],
		data: () => ({
			home,
			arrowBack,
		}),
	});
</script>
