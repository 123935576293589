<template>
	<ion-list class="row row-center"><!-- lines="none"-->
		<ion-item lines="none">
			<canvas id="signature-pad" :width="signatureWidth" :height="signatureHeight"></canvas>
		</ion-item>
		<ion-item class="row"><!-- lines="none"-->
			<ion-button fill="outline" size="large" @click="saveSignature()">{{ saveSign }}Salva</ion-button>
			<ion-button fill="outline" size="large" @click="removeSignature()">{{ removeSign }}Rimuovi</ion-button>
			<ion-button fill="outline" size="large" @click="discardSignature()">{{ discardSign }}Pulisci</ion-button>
		</ion-item>
	</ion-list>
</template>

<style scoped>
	#signature-pad {
		left: 0;
		top: 0;
		background-color: lightgrey;
	}
</style>

<script>
import { IonButton, IonItem, IonList } from "@ionic/vue";
import SignaturePad from 'signature_pad';
import { defineComponent } from "vue";

	export default defineComponent({
		name: "Signature",
		emits: [ "saved", "removed" ],
		data: () => ({
			signature: null,
			signatureWidth: 0,
			signatureHeight: 0,
		}),
		components: {
			IonList,
			IonItem,
			IonButton,
		},
		mounted () {
			this.initSignature();
			this.onResize();
			window.addEventListener('resize', this.onResize);
		},
		beforeUnmount() { 
			window.removeEventListener('resize', this.onResize);
		},

		methods: {
			initSignature() {
				this.signature = new SignaturePad(document.getElementById('signature-pad'), {
					backgroundColor: 'rgba(255, 255, 255, 0)',
					penColor: 'rgb(0, 0, 0)'
				});
			},
			dataURLtoFile(dataUrl, fileName){
				var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1], bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
				while(n--){
					u8arr[n] = bstr.charCodeAt(n);
				}
				return new File([u8arr], fileName, {type:mime});
			},
			saveSignature() {
				if (this.signature.isEmpty() == false) {
					var image_url = this.signature.toDataURL('image/png');
					//console.log(image_url);
					var signatureFile = this.dataURLtoFile(image_url,'Signature.png');
					setTimeout(() => this.emitImageSaved(signatureFile), 0);
				}
			},
			emitImageSaved(file) {
				this.$emit('saved', file);
			},
			removeSignature() {
				this.signature.clear();
				this.$emit('removed');
			},
			onResize() {
				this.signatureWidth = window.innerWidth - 50
				this.signatureHeight = window.innerHeight / 2.5
			}
		},
	});
</script>

