<template>
	<ion-page>
		<spot-header :title="pageTitle"></spot-header>
		<ion-content :fullscreen="true">
			<web-socket-barcode @scanned="onScan" v-if="isActive"></web-socket-barcode>
			<div class="search-container">
				<ion-item class="input-form">
					<spot-button size="small" color="primary" icon="locationSharp" :enabled="false" :expand="null" :text="null">
					</spot-button>
					<ion-input name="bPosition" id="bPosition" type="text" :placeholder="barcodePositionText" v-model="positionBarcode" v-on:keyup.enter="positionSearch"></ion-input> <!--@keypress.enter="onScan()"-->
				</ion-item>
				<ion-item class="input-form">
					<spot-button size="small" color="primary" icon="searchOutline" :enabled="false" :expand="null" :text="null">
					</spot-button>
					<ion-input name="bPackage" id="bPackage" type="text" :placeholder="barcodePackageText" v-model="packageBarcode" v-on:keyup.enter="packageSearch"></ion-input> <!--@keypress.enter="onScan()"-->
				</ion-item>
			</div>
			<div class="actions-container">
				<router-link :to="`/insert`"> <!-- :to="`/search?direction=shipments/deliveries`"-->
					<div><ion-icon class="action-icons" :icon="create"></ion-icon></div>
					<div><ion-label>{{ newActivityText }}</ion-label></div>
				</router-link>
				<router-link :to="`/insert/multiple`"> <!-- :to="`/search?direction=shipments/deliveries`"-->
					<div><ion-icon class="action-icons" :icon="layers"></ion-icon></div>
					<div><ion-label>{{ newMultipleText }}</ion-label></div>
				</router-link>
			</div>
			<div class="actions-container">
				<router-link :to="`/search`"> <!-- :to="`/search?direction=shipments/deliveries`"-->
					<div><ion-icon class="action-icons" :icon="archive"></ion-icon></div>
					<div><ion-label>{{ oldActivityText }}</ion-label></div>
				</router-link>
			</div>
		</ion-content>
 </ion-page>
</template>

<style scoped>
	.search-container {
		margin-top: 1em;
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.input-form {
		font-size: 3vh;
		text-align: left;
		display: flex;
		flex-direction: row;
		justify-content: left;
		align-items: center;
		margin-top: 0.5em;
	}

	.actions-container {
		margin-top: 2em;
		text-align: center;
		border-radius: 5em !important;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}
	.action-icons {
  font-size: 128px;
		margin-left: .2em;
		margin-right: .2em;
	}
	router-link {
		display: flex;
		flex-direction: column;
	}
	ion-label {
		color: var(--ion-color-medium);
	}
</style>

<script>
import WebSocketBarcode from "@/components/WebSocketBarcode.vue";
import { feName } from "@/plugins/variables.js";
import { IonContent, IonIcon, IonInput, IonItem, IonLabel, IonPage } from '@ionic/vue';
import { archive, create, layers, locationSharp, searchOutline } from "ionicons/icons";
import { defineComponent } from 'vue';
import SpotButton from "../components/SpotButton.vue";
import SpotHeader from "../components/SpotHeader.vue";
import { checkUserSession, showMsgError, showMsgInfo, showMsgWarning } from "../plugins/common.js";

	export default defineComponent({
		name: 'Home',
		props: {
			pageTitle: { type: String, default: "Benvenuto in " + feName },
			barcodePositionText: { type: String, default: "Trova ubicazione articolo" },
			barcodePackageText: { type: String, default: "Ricerca tramite barcode" },
			newActivityText: { type: String, default: "Nuova registrazione" },
			newMultipleText: { type: String, default: "Consegne/Ritiri multipli" },
			oldActivityText: { type: String, default: "Archivio registrazioni" },
			searchTitle: { type: String, default: "Ricerca Spedizione/Ricezione" },
			nullBarcode: { type: String, default: "Non è stato inserito alcun barcode da cercare" },
			invalidProjectMsg: { type: String, default: "Barcode trovato, ma esterno a " + feName },
			foundPosition: { type: String, default: "La posizione dell'articolo è la seguente: " },
			notFoundPosition: { type: String, default: "Articolo esistente, ma la posizione non era stata inserita!" },
			notFoundMsg: { type: String, default: "Nessun pacco trovato, corrispondente al barcode inserito!" },
			unselectedChoice: { type: String, default: "Occorre selezionare il campo di input, prima di leggere il barcode!" },
		},
		components: {
			IonPage,
			IonContent,
			IonItem,
			IonIcon,
			IonInput,
			IonLabel,
			SpotHeader,
			SpotButton,
			WebSocketBarcode,
		},
		data: () => ({
			isActive: null,
			positionBarcode: null,
			packageBarcode: null,
			searchOutline,
			locationSharp,
			archive,
			create,
			layers,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
			checkUserSession,
		}),
		ionViewWillEnter() {
			this.positionBarcode=null;
			this.packageBarcode=null;
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			positionSearch() {
				if (this.positionBarcode && this.positionBarcode.length>0) {
					let currSearch = {
						params: {
							'q[code_eq]': this.positionBarcode,
							//'q[delegate_id_null]': true //to show only not closed
						}
					}
					this.$store
					.dispatch('tables/querySearch', { model: 'project_rows', data: currSearch })
					.then(data => {
						if (data.length > 0) {
							if (data[0].project.receiving === true ||  data[0].project.shipping === true) {
								if (data[0].location) {
									this.showMsgInfo(this.searchTitle,this.foundPosition + "<br><br>" + data[0].location.name )
								} else {
									this.showMsgInfo(this.searchTitle,this.notFoundPosition)
								}
							} else {
								this.showMsgWarning(this.searchTitle,this.invalidProjectMsg)
							}
						}
						else {
							this.showMsgWarning(this.searchTitle,this.notFoundMsg)
						}
					})
					.catch(error => { this.checkUserSession(error, "warning"); })
				}
				else {
					this.showMsgError(this.searchTitle,this.nullBarcode)
				}
			},
			packageSearch() {
				if (this.packageBarcode && this.packageBarcode.length>0) {
					let currSearch = {
						params: {
							'q[code_eq]': this.packageBarcode,
							//'q[delegate_id_null]': true //to show only not closed
						}
					}
					this.$store
					.dispatch('tables/querySearch', { model: 'project_rows', data: currSearch })
					.then(data => {
						if (data.length > 0) {
							if (data[0].project.receiving === true) {
								this.$router.push(`/finalize/receipt/`+data[0].id);
							} else if (data[0].project.shipping === true) {
								this.$router.push(`/finalize/shipment/`+data[0].id);
							} else {
								this.showMsgWarning(this.searchTitle,this.invalidProjectMsg)
							}
						}
						else {
							this.$store
							.dispatch('tables/querySearch', { model: 'project', data: currSearch })
							.then(data => {
								if (data.length > 0) {
									if (data[0].receiving === true || data[0].shipping === true) {
										//if (!data[0].employee) this.$router.push(`/search/` + data[0].id + `?code=` + data[0].code);
										if (!data[0].employee) this.$router.push(`/search/` + data[0].id + `?code=` + data[0].code);
										else this.$router.push(`/revision/` + data[0].id + `?code=` + data[0].code);
									} else {
										this.showMsgWarning(this.searchTitle,this.invalidProjectMsg)
									}
								}
								else {
									this.showMsgWarning(this.searchTitle,this.notFoundMsg)
								}
							})
							.catch(error => { this.checkUserSession(error, "warning"); })						
						}
					})
					.catch(error => { this.checkUserSession(error, "warning"); })
				}
				else {
					this.showMsgError(this.searchTitle,this.nullBarcode)
				}
			},
			onScan(barcode) {
				if(this.isActive == true) {
					switch (document.activeElement.name) {
						case 'bPosition':
							this.positionBarcode = barcode
							this.positionSearch()
							break;
						case 'bPackage':
							this.packageBarcode = barcode;
							this.packageSearch()
							break;
						default:
							this.showMsgWarning(this.searchTitle,this.unselectedChoice)
					}
				}
			},
		},
	});
</script>
