<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :showLogo="false" @pressedGoHome="$router.push('/home')"></spot-header>
		<ion-content :fullscreen="true" v-if="isActive">
			<div id="search" v-if="!showResults">
				<div v-if="currField.id==0"> <!-- currField.name == 'employee' -->
					<spot-select-custom
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.employees"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						:custom-label="customEmployee"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection">
					</spot-select-custom>
				</div>
				<ion-item v-for="option in searchOptions" :key="option.id">
					<ion-label class="select-option">{{option.text}}</ion-label>
					<ion-checkbox slot="start" v-model="option.isChecked">
					</ion-checkbox>
				</ion-item>
				<spot-button fill="outline" size="large" color="primary" icon="searchCircle" :enabled="!isEmptyRequiredFields()" :expand="null" :text="buttonSearchText" @clicked="executeSearch()">
				</spot-button>
			</div>
			<div class="results" v-if="showResults"> <!-- v-else -->
				<div class="package-title" v-if="packageCode">{{ labelPackage }} {{ packageCode }}</div>
				<div class="group-title">
					<ion-label v-if="receiptsFound.length > 0">{{ labelReceivingText }} ({{ receiptsFound.length }})</ion-label>
				</div>
				<ion-item v-for="item in receiptsFound" :key="item.id">
					<ion-label>{{item.code}}</ion-label>
					<ion-icon v-if="item.closed" :icon="bagCheck"></ion-icon>
					<spot-button fill="solid" size="default" color="primary" icon="logInOutline" :expand="null" :text="buttonOpenText" @clicked="openReceipt(item.id)">
					</spot-button>
				</ion-item>
				<div class="group-title">
					<ion-label v-if="shipmentsFound.length > 0">{{ labelShippingText }} ({{ shipmentsFound.length }})</ion-label>
				</div>
				<ion-item v-for="item in shipmentsFound" :key="item.id">
					<ion-label>{{item.code}}</ion-label>
					<ion-icon v-if="item.closed" :icon="bagCheck"></ion-icon>
					<spot-button fill="solid" size="default" color="primary" icon="logOutOutline" :expand="null" :text="buttonOpenText" @clicked="openShipment(item.id)">
					</spot-button>
				</ion-item>
				<spot-button fill="clear" size="large" color="primary" icon="chevronBackCircleOutline" :expand="null" :text="buttonGoBackText" @clicked="goBack()">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				Current Field: {{ currField }}<br>
				<div v-for="field in fields.list" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style> <!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<style scoped>
	ion-label {
		font-weight:bold;
	}
	.select-option {
		margin-right: .5em;
	}
	.results {
		margin-top: .5em;
	}
	ion-item {
		margin-left: .5em;
		margin-right: .5em;
	}
	.package-title {
		font-weight: bold;
		text-align: center;
	}
	.group-title {
		font-weight: bold;
		margin-left: 3em;
		margin-top: 1em;
	}
</style>

<script>
import { IonCheckbox, IonContent, IonIcon, IonItem, IonLabel, IonPage } from "@ionic/vue";
import { bagCheck, chevronBackCircleOutline, logInOutline, logOutOutline, searchCircle } from "ionicons/icons";
import { defineComponent } from "vue";
import SpotButton from "../components/SpotButton.vue";
import SpotHeader from "../components/SpotHeader.vue";
import SpotSelectCustom from "../components/SpotSelectCustom.vue";
import { checkUserSession, settings } from "../plugins/common.js";

	export default defineComponent({
		name: "Search",
		props: {
			pageTitle: { type: String, default: "Cerca" },
			buttonSearchText: { type: String, default: "Ricerca" },
			buttonGoBackText: { type: String, default: "Nuova Ricerca" },
			buttonOpenText: { type: String, default: "Apri" },
			labelReceivingText: { type: String, default: "RICEZIONI" },
			labelShippingText: { type: String, default: "SPEDIZIONI" },
			messageTitle: { type: String, default: "RICERCA RICEZIONI/SPEDIZIONI" },
			labelPackage: { type: String, default: "Oggetti in:" },
			errorSearchMessage: { type: String, default: "Errore durante la ricerca" },
		},
		components: {
			IonPage,
			IonItem,
			IonIcon,
			IonContent,
			IonLabel,
			IonCheckbox,
			SpotHeader,
			SpotSelectCustom,
			SpotButton,
		},
		data: () => ({
			isActive: null,
			isClosed: null,
			showResults: false,
			packageCode: null,
			fields: {
				currentId: 0,
				list: [
					{ id: 0, name: 'employee', text: 'Mittente / Destinatario', allignLabel: false, placeholder: 'Seleziona Dipendente', inputType: "combo", defaultValue: null, value: null, required: false, refField: 'id', canAddItem: false, canSkip: false, canGoBack: false, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
				],
			},
			tablesData: {
				employees: [],
				projectRows: [],
			},
			searchOptions: [
				{ id: 0, name: 'Receipts', text: 'Ricezioni Aperte', isChecked: false },
				{ id: 1, name: 'Shipments', text: 'Spedizioni Aperte', isChecked: false },
				{ id: 2, name: 'All', text: 'Tutto', isChecked: false }
			],
			searchCircle,
			logInOutline,
			logOutOutline,
			bagCheck,
			chevronBackCircleOutline,
			settings,
			checkUserSession,
		}),
		computed: {
			currField() {
				return this.fields.list[this.fields.currentId]
			},
			receiptsFound() {
				return this.tablesData.projectRows.filter(function (projectRow) {
					return (projectRow.project.receiving == true)
				})
			},
			shipmentsFound() {
				return this.tablesData.projectRows.filter(function (projectRow) {
					return (projectRow.project.shipping == true)
				})
			},
			allOpened() {
				if (this.fields.list[0].value) {
					return {
						params: {
							'q[employee_id_eq]': this.fields.list[0].value.id,
							'q[closed_eq]': false, //to show only not closed
						}
					}
				}
				else {
					return {
						params: {
							'q[employee_id_null]': true,
							'q[closed_eq]': false, //to show only not closed
						}
					}
				}
			},
			allReceiptsOpened() {
				if (this.fields.list[0].value) {
					return {
						params: {
							'q[employee_id_eq]': this.fields.list[0].value.id,
							'q[project_receiving_eq]': true,
							'q[closed_eq]': false, //to show only not closed
						}
					}
				}
				else {
					return {
						params: {
							'q[employee_id_null]': true,
							'q[project_receiving_eq]': true,
							'q[closed_eq]': false, //to show only not closed
						}
					}
				}
			},
			allShipmentsOpened() {
				if (this.fields.list[0].value) {
					return {
						params: {
							'q[employee_id_eq]': this.fields.list[0].value.id,
							'q[project_shipping_eq]': true,
							'q[closed_eq]': false, //to show only not closed
						}
					}
				}
				else {
					return {
						params: {
							'q[employee_id_null]': true,
							'q[project_shipping_eq]': true,
							'q[closed_eq]': false, //to show only not closed
						}
					}
				}
			},
			allEmployeeRows() {
				if (this.fields.list[0].value) {
					return {
						params: {
							'q[employee_id_eq]': this.fields.list[0].value.id,
						}
					}
				}
				else {
					return {
						params: {
							'q[employee_id_null]': true,
						}
					}
				}
			},
			allProjectRows() {
				return {
					params: {
						'q[project_id_eq]': this.$route.params.id,
					}
				}
			},
		},
		ionViewWillEnter() {
			this.getAllTablesData();
			this.setDefault();
			this.getProjectRows();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			//Settings
			getPlaceHolder() {
				return (this.settings.showFieldsPlaceholder) ? this.currField.placeholder : ''
			},
			isEmptyRequiredFields() {
				let ret = false;
				this.fields.list.forEach(element => {if (element.required && !element.value) ret=true});
				if (ret==false) {
					ret=true;
					this.searchOptions.forEach(element => {if (element.isChecked == true) ret=false});
				}
				return ret;
			},
			customEmployee ({ name, surname, department, seat, seat_code }) {
				let infoDepartment = "";
				if (department)
					infoDepartment = " (" + department.name + ")"
				else if (seat)
					infoDepartment = " (" + seat + ")"
				else if (seat_code)
					infoDepartment = " (" + seat_code + ")"
				return name + " " + surname + infoDepartment
			},
			//Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch("tables/querySearch", { model: tableName, data: filters })
				return data;
			},
			async getData(tableName) {
				let data = await this.$store.dispatch("tables/fetch", { model: tableName })
				return data;
			},
			getAllTablesData() {
				this.getEmployees();
			},
			getEmployees() {
				this.getData("employees")
				.then(data => { this.tablesData.employees = data; })
				.catch(error => { this.tablesData.employees = []; this.checkUserSession(error, "warning"); });
			},
			searchRecords(currSearch) {
				this.tablesData.projectRows = [];
				this.searchData("project_rows", currSearch)
				.then(data => {
					this.tablesData.projectRows = data;
					this.showResults = true;
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitle, this.errorSearchMessage);
					this.showResults = false;
				})
			},
			//Actions
			detectSelection(value) {
				this.currField.value = value;
			},
			detectUnselection() {
				this.currField.value = this.currField.defaultValue;
			},
			getProjectRows() {
				this.showResults=true;
				if (this.$route.params.id) {
					this.packageCode=this.$route.query.code;
					this.searchRecords(this.allProjectRows);
				}
				else {
					this.packageCode=null;
					this.showResults=false;
				}
			},
			executeSearch() {
				if (this.searchOptions[2].isChecked==true) {
					this.searchRecords(this.allEmployeeRows);
				}
				else {
					if (this.searchOptions[0].isChecked==true && this.searchOptions[1].isChecked==false) this.searchRecords(this.allReceiptsOpened);
					if (this.searchOptions[0].isChecked==false && this.searchOptions[1].isChecked==true) this.searchRecords(this.allShipmentsOpened);
					if (this.searchOptions[0].isChecked==true && this.searchOptions[1].isChecked==true) this.searchRecords(this.allOpened);
				}
			},
			openReceipt(movementId) {
						this.$router.push(`/finalize/receipt/`+movementId);
			},
			openShipment(movementId) {
						this.$router.push(`/finalize/shipment/`+movementId);
			},
			setDefault() {
				this.fields.currentId = 0;
				this.fields.list.forEach(element => element.value = element.defaultValue);
				this.searchOptions.forEach(element => element.isChecked = false);
			},
			addItem(tableName) {
				if (tableName) {
				//TODO: Implementa la funzionalità generica AddItem
				}
			},
			goBack() {
				this.showResults = false;
			},
		},
	});
</script>