<template>
	<ion-page>
		<spot-header :title="pageTitle" :canGoHome="true" :showLogo="false" @pressedGoHome="$router.push('/home')"></spot-header>
		<ion-content :fullscreen="true">
			<div id="fieldsContainer" class="content-rows" v-if="isActive">
				<web-socket-barcode @scanned="onScan"></web-socket-barcode>
				<div v-if="currField.id==0"> <!-- currField.name == 'movementType' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.movementTypes"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection">
					</spot-select>
				</div>
				<div v-if="currField.id==1"> <!-- currField.name == 'quantity' -->
					<spot-input
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:type="currField.inputType"
						:clearInput="true"
						:canUnlock="currField.canUnlock"
						@textChanged="changedInput"
						@committed="committedInput">
					</spot-input>
				</div>
				<div v-if="currField.id==2"> <!-- currField.name == 'employee' -->
					<spot-select-custom
						:ref="currField.name"
						:label="this.fields.list[0].value.id == 0 ? receivingEmployee : shipmentEmployee"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.employees"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						:custom-label="customEmployee"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addEmployee">
					</spot-select-custom>
				</div>
				<div v-if="currField.id==3"> <!-- currField.name == 'carrier' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.carriers"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addCarrier">
					</spot-select>
				</div>
				<div v-if="currField.id==4"> <!-- currField.name == 'supplier' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.suppliers"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addSupplier">
					</spot-select>
				</div>
				<div v-if="currField.id==5"> <!-- currField.name == 'item' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.items"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addItem">
					</spot-select>
				</div>
				<div v-if="currField.id==6"> <!-- currField.name == 'tracking_number' -->
					<spot-input
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:type="currField.inputType"
						:clearInput="true"
						:canUnlock="currField.canUnlock"
						@textChanged="changedInput"
						@committed="committedInput">
					</spot-input>
				</div>
				<div v-if="currField.id==7"> <!-- currField.name == 'transport_document' -->
					<spot-input
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:type="currField.inputType"
						:clearInput="true"
						:canUnlock="currField.canUnlock"
						@textChanged="changedInput"
						@committed="committedInput">
					</spot-input>
				</div>
				<div v-if="currField.id==8"> <!-- currField.name == 'movementHeader' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="items-data">
						<ion-button fill="outline" size="large" @click="createAndPrint()">
							{{ !currField.value ? (canPrint ? labelSave + labelAnd + labelPrint : labelSave) : (canPrint ? labelReprint : null) }}
							<ion-icon v-if="!currField.value" slot="icon-only" :icon="save" size="large"></ion-icon>
							<ion-icon v-if="canPrint" slot="icon-only" :icon="print" size="large"></ion-icon>
						</ion-button>
					</div>
				</div>
			</div>
			<div id="actionsContainer">
				<spot-button
					v-if="isVisibleButton(currField.canGoBack)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronBackCircleOutline"
					:enabled="currField.canGoBack && !fields.list[this.fields.list.length-1].value"
					:expand="null"
					:text=null
					@clicked="goBack()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canGoAhead)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronForwardCircleOutline"
					:enabled="!isDisableButton(currField.canGoAhead)"
					:expand="null"
					:text=null
					@clicked="goAhead()">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				Current Field: {{ currField }}<br>
				<div v-for="field in fields.list" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style> <!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<style scoped>
	ion-label {
		font-weight:bold;
	}
	.content-rows {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		/*justify-content: space-around;*/
		/*align-content: flex-start;*/
		/*padding: 1em;*/
		/*height: 10%;*/
		/*overflow: auto;*/
	}
	.items-label {
		margin-top: .5em;
		margin-left: 1em;
	}
	.items-data {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		justify-content: space-between;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
</style>

<script>
import WebSocketBarcode from "@/components/WebSocketBarcode.vue";
import { IonButton, IonContent, IonIcon, IonLabel, IonPage } from "@ionic/vue";
import { chevronBackCircleOutline, chevronForwardCircleOutline, print, save } from "ionicons/icons";
import { defineComponent } from "vue";
import SpotButton from "../components/SpotButton.vue";
import SpotHeader from "../components/SpotHeader.vue";
import SpotInput from "../components/SpotInput.vue";
import SpotSelect from "../components/SpotSelect.vue";
import SpotSelectCustom from "../components/SpotSelectCustom.vue";
import { checkUserSession, confirmYesNo, getUserInfo, insertValue, settings, showMsgError, showMsgInfo, showMsgWarning } from "../plugins/common.js";

export default defineComponent({
		name: "InsertMultiple",
		props: {
			pageTitle: { type: String, default: "Registrazione multipla" },
			labelPrint: { type: String, default: "Stampa" },
			labelReprint: { type: String, default: "Ristampa" },
			labelSave: { type: String, default: "Salva" },
			labelAnd: { type: String, default: " e " },
			labelContinue: { type: String, default: "Prosegui" },
			receivingEmployee: { type: String, default: "Destinatario" },
			shipmentEmployee: { type: String, default: "Richiedente" },
			newValueTitle: { type: String, default: "Inserimento Nuovo Valore" },
			messageTitleInsert: { type: String, default: "Creazione Sovracollo" },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			successSaveMessage: { type: String, default: "Sovracollo creato" },
			errorSaveMessage: { type: String, default: "Errore durante la creazione del sovracollo" },
			successMailMessage: { type: String, default: "E-mail inviata a: " },
			errorMailMessage: { type: String, default: "Errore durante l'invio della mail" },
			successPrintMessage: { type: String, default: "Stampa sovracollo avvenuta correttamente. Etichetta: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa del sovracollo" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
			unselected: { type: String, default: "Nessuno" },
		},
		components: {
			IonPage,
			IonButton,
			IonIcon,
			IonContent,
			IonLabel,
			SpotHeader,
			SpotSelect,
			SpotSelectCustom,
			SpotInput,
			SpotButton,
			WebSocketBarcode,
		},
		data: () => ({
			isActive: null,
			isClosed: null,
			header_id: null,
			header_code: null,
			printer_id: null,
			fields: {
				currentId: 0,
				list: [
					//TODO: controlla il canSkip come è meglio metterlo (quando ho temrinato l'implementazione del modulo)
					{ id: 0, name: 'movementType', text: 'Tipo Movimento', allignLabel: false, placeholder: 'Seleziona Tipo Movimento', refTable: null, inputType: "combo", defaultValue: null, value: null, required: true, refField: 'text', canAddItem: false, canSkip: true, canGoBack: false, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 1, name: 'quantity', text: 'Quantità', allignLabel: false, placeholder: 'Inserisci nr. Articoli', refTable: null, inputType: "number", defaultValue: null/*1*/, value: null, required: true, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 2, name: 'employee', text: 'Dipendente', allignLabel: false, placeholder: 'Seleziona Dipendente', refTable: 'employees', inputType: "combo", defaultValue: null/*1*/, value: null, required: true, refField: 'id', canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 3, name: 'carrier', text: 'Corriere', allignLabel: false, placeholder: 'Seleziona Corriere', refTable: 'carriers', inputType: "combo", defaultValue: null, value: null, required: false, refField: 'name', canAddItem: true, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 4, name: 'supplier', text: 'Mittente', allignLabel: false, placeholder: 'Seleziona Mittente', refTable: 'suppliers', inputType: "combo", defaultValue: null, value: null, required: false, refField: 'name', canAddItem: true, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 5, name: 'item', text: 'Formato', allignLabel: false, placeholder: 'Seleziona Formato Spedizione', refTable: 'items', inputType: "combo", defaultValue: null, value: null, required: false, refField: 'code', canAddItem: true, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 6, name: 'tracking_number', text: 'Track ID', allignLabel: false, placeholder: 'Inserisci ID Spedizione', refTable: null, inputType: "text", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 7, name: 'transport_document', text: 'DDT', allignLabel: false, placeholder: 'Inserisci Nr. Documento di Trasporto', refTable: null, inputType: "text", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 8, name: 'movementHeader', text: 'Salva e Stampa Sovracollo', allignLabel: false, placeholder: 'Salva e Stampa', refTable: null, inputType: "button", defaultValue: null, value: null, required: true/*false*/, canAddItem: false, canSkip: false, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
				],
			},
			tablesData: {
				movementTypes: [
					{ id: 0, name: 'Receipt', text: 'RICEZIONE', value: 'Receipt'},
					{ id: 1, name: 'Shipment', text: 'SPEDIZIONE', value: 'Shipment'},
				],
				carriers: [],
				suppliers: [],
				employees: [],
				items: [],
			},
			//showActionButtonsText : false,
			print,
			save,
			chevronBackCircleOutline,
			chevronForwardCircleOutline,
			settings,
			getUserInfo,
			confirmYesNo,
			checkUserSession,
			insertValue,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
		}),
		computed: {
			currField() {
				return this.fields.list[this.fields.currentId]
			},
			canPrint() {
				return (this.settings.printerManagement && (this.printer_id != null))
			}
		},
		async ionViewWillEnter() {
			await this.setDefault();
			this.getAllTablesData();
			this.getPrinter();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			//Settings
			getPlaceHolder() {
				return ((this.settings.showFieldsPlaceholder) ? (this.currField.required ? this.currField.placeholder : this.unselected ) : '')
			},
			isDisableButton(ability) {
				if (this.settings.fieldsCheckOnButtons && ability) {
					return (this.currField.required && !this.currField.value) ?  true : false
				}
				else {
					return !ability
				}
			},
			isVisibleButton(ability) {
				return (this.settings.hideUnneededButtons) ? ability : true
			},
			customEmployee ({ name, surname, department, seat, seat_code }) {
				let infoDepartment = "";
				if (department)
					infoDepartment = " (" + department.name + ")"
				else if (seat)
					infoDepartment = " (" + seat + ")"
				else if (seat_code)
					infoDepartment = " (" + seat_code + ")"
				return name + " " + surname + infoDepartment
			},
			//Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch("tables/querySearch", { model: tableName, data: filters })
				return data;
			},
			async getData(tableName) {
				let data = await this.$store.dispatch("tables/fetch", { model: tableName })
				return data;
			},
			async readData(tableName, recordId) {
				let data = await this.$store.dispatch("tables/read", { model: tableName, id: recordId })
				return data;
			},
			async writeData(tableName, record) {
				let data = await this.$store.dispatch("tables/create", { model: tableName, data: record })
				return data;
			},
			getAllTablesData() {
				this.getCarriers();
				this.getSuppliers();
				this.getEmployees();
				this.getItems();
			},
			getCarriers() {
				this.getData("carriers")
				.then(data => { this.tablesData.carriers = data; })
				.catch(error => { this.tablesData.carriers = []; this.checkUserSession(error, "warning"); });
			},
			getSuppliers() {
				this.getData("suppliers")
				.then(data => { this.tablesData.suppliers = data; })
				.catch(error => { this.tablesData.suppliers = []; this.checkUserSession(error, "warning"); });
			},
			getEmployees() {
				this.getData("employees")
				.then(data => { this.tablesData.employees = data; })
				.catch(error => { this.tablesData.employees = []; this.checkUserSession(error, "warning"); });
			},
			getItems() {
				this.getData("items")
				.then(data => { this.tablesData.items = data; })
				.catch(error => { this.tablesData.items = []; this.checkUserSession(error, "warning"); });
			},
			getPrinter() {
				if (this.settings.printerManagement) {
					let userInfo = this.getUserInfo();
					if (!userInfo || !userInfo.plant || !userInfo.plant.printer_id) {
						let currSearch = {
							params: {
								'q[default_eq]': true,
							}
						}
						this.searchData("printers", currSearch)
						.then(data => {
							if (data.length>0) {
								this.printer_id = data[0].id
							}
							else {
								this.printer_id = null;
								this.confirmYesNo(this.messageTitlePrint, this.warningPrintConfirmMessage)
								.then((data) => {
									if (data == false) this.$router.push("/home");
								})
							}
						})
						.catch(error => { this.printer_id = null; this.checkUserSession(error, "warning"); });
					}
					else {
						this.printer_id = userInfo.plant.printer_id;
					}
				}
				else {
						this.printer_id = null;
				}
			},
			prepareNewRecordShipment() {
				let myRecord = {
					project: {
						name: "MULTI",
						receiving: (this.fields.list[0].value.id == 0) ? true : false,
						shipping: (this.fields.list[0].value.id == 1) ? true : false,
						expected_project_rows_count:  (this.fields.list[1].value) ? ((this.fields.list[1].value.length > 0) ? this.fields.list[1].value : null) : null,
						employee_id: (this.fields.list[2].value) ? this.fields.list[2].value.id : null,
					}
				};
				return myRecord;
			},
			prepareNewRecordReceipt() {
				let myRecord = {
					project: {
						name: "MULTI",
						receiving: (this.fields.list[0].value.id == 0) ? true : false,
						shipping: (this.fields.list[0].value.id == 1) ? true : false,
						expected_project_rows_count:  (this.fields.list[1].value) ? ((this.fields.list[1].value.length > 0) ? this.fields.list[1].value : null) : null,
						employee_id: (this.fields.list[2].value) ? this.fields.list[2].value.id : null,
						carrier_id: (this.fields.list[3].value) ? this.fields.list[3].value.id : null,
						supplier_id: (this.fields.list[4].value) ? this.fields.list[4].value.id : null,
						item_id: (this.fields.list[5].value) ? this.fields.list[5].value.id : null,
						tracking_number: (this.fields.list[6].value) ? ((this.fields.list[6].value.length > 0) ? this.fields.list[6].value : null) : null,
						transport_document: (this.fields.list[7].value) ? ((this.fields.list[7].value.length > 0) ? this.fields.list[7].value : null) : null,
					}
				};
				return myRecord;
			},
			saveRecord() {
				let myNewProject = null;
				let direction = "";
				let currEmployee = this.fields.list[2].value.name + ` ` + this.fields.list[2].value.surname;
				let mailMessage = "<br><br>" + this.successMailMessage + "&nbsp" + this.fields.list[2].value.name + "&nbsp" + this.fields.list[2].value.surname;
				if (this.fields.list[0].value.id == 0) {
					myNewProject = this.prepareNewRecordReceipt();
					direction = "DESTINATARIO:";
				}
				if (this.fields.list[0].value.id == 1) {
					myNewProject = this.prepareNewRecordShipment();
					direction = "MITTENTE:";
				}
				this.writeData("project", myNewProject)
				.then(data => {
					this.header_id = data.id;
					this.header_code = data.code;
					this.currField.value=true;
					if (this.canPrint && this.printer_id != null) {
						this.readData("printers", this.printer_id)
						.then(data => {
							if (data.is_online == true) {
								this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.header_code + `&direction=` + direction + `&employee=` + currEmployee)
								.then(() => {
									this.showMsgInfo(
										this.messageTitleInsert,
										this.successSaveMessage + mailMessage + "<br><br>" + this.successPrintMessage + "&nbsp" + this.header_code);
								})
								.catch(error => {
									this.checkUserSession(error, "warning");
									this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
								})
							}
							else {
								this.showMsgWarning(
										this.messageTitleInsert,
										this.successSaveMessage + mailMessage + "<br><br>" + this.warningPrinterOffline);
							}
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgInfo(
							this.messageTitleInsert,
							this.successSaveMessage + mailMessage);
						this.goAhead();
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
				})
			},
			//Actions
			onScan(barcode) {
				if(this.isActive == true) {
					switch (this.currField.id) {
						case 6:
						case 7:
							this.currField.value = barcode
							this.committedInput()
							break;
						default:
							// this.showMsgWarning("Barcode","non è ammessa la lettura di barcode")
					}
				}
			},
			async setDefault() {
				await this.getData("printers")
				.then(data => { (data.length > 0) ? this.settings.printerManagement = true : this.settings.printerManagement = false ; })
				.catch(error => { this.checkUserSession(error, "warning"); });
				this.fields.currentId = 0;
				this.fields.list.forEach(element => element.value = element.defaultValue);
				this.header_id = null;
				this.header_code = null;
			},
			detectReturn(keyCode) { //TODO: qua forse c'è da fare ulteriori check in funzione del campo (vedi onscan come esempio)
				if (keyCode == 13) setTimeout(() => this.goAhead(), 0);
			},
			changedInput(value) { //TODO: qua forse c'è da fare ulteriori check in funzione del campo (vedi onscan come esempio)
				this.currField.value = value;
			},
			committedInput() { //TODO: qua forse c'è da fare ulteriori check in funzione del campo (vedi onscan come esempio)
				setTimeout(() => this.goAhead(), 0);
			},
			detectSelection(value) {
				this.currField.value = value;
				if (this.settings.nextFieldOnComboSelect) setTimeout(() => this.goAhead(), 0);
			},
			detectUnselection() {
				this.currField.value = this.currField.defaultValue;
			},
			goBack() { //TODO: Queste sono da modificare per gestire la diversità tra ricezione e spedizione
				if (this.fields.currentId >= 0 ) {
					if (this.fields.list[0].value.id == 1 && (this.fields.currentId == this.fields.list.length-1)) this.fields.currentId=2;
					else this.fields.currentId-=1;
				}
			},
			goAhead() { //TODO: Queste sono da modificare per gestire la diversità tra ricezione e spedizione
				if (this.fields.currentId < (this.fields.list.length-1) ) {
					if (this.fields.list[0].value.id == 1 && (this.fields.currentId == 2)) this.fields.currentId=this.fields.list.length-1;
					else this.fields.currentId+=1
				}
				else this.executeLastFieldAction()
			},
			goToPrint() {
				if (this.fields.currentId >= 0 && this.fields.currentId < (this.fields.list.length-1) ) this.fields.currentId=this.fields.list.length-1
			},
			addCarrier() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								carriers: {
									name: data,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getCarriers(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			addSupplier() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								suppliers: {
									name: data,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getSuppliers(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			addEmployee() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								employees: {
									name: data,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getEmployees(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			addItem() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								items: {
									code: data,
									location_id: 1,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getItems(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			createAndPrint() {
				if (!this.currField.value) {
					this.saveRecord();
				}
				else {
					let direction = "";
					let currEmployee = this.fields.list[2].value.name + ` ` + this.fields.list[2].value.surname;
					if (this.fields.list[0].value.id == 0) direction = "DESTINATARIO:";
					if (this.fields.list[0].value.id == 1) direction = "MITTENTE:";

					if (this.canPrint && this.printer_id != null) {
						this.readData("printers", this.printer_id)
						.then(data => {
							if (data.is_online == true) {
								this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.header_code + `&direction=` + direction + `&employee=` + currEmployee)
								.then(() => {
									this.showMsgInfo(
										this.messageTitlePrint,
										this.successPrintMessage + "&nbsp" + this.header_code);
								})
								.catch(error => {
									this.checkUserSession(error, "warning");
									this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
								})
							}
							else {
								this.showMsgWarning(
										this.messageTitlePrint,
										this.warningPrinterOffline);
							}
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
				}
			},
			executeLastFieldAction() {
				if (this.settings.fieldsCheckOnButtons == true) {
					this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
					//TODO: aggiungi checkUserSession (ma non dovrebbe mai arrivarci)
				}
			},
		},
	});
</script>
