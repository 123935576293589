<template>
	<ion-page>
		<spot-header :title="recActivity + pageTitle" :canGoHome="true" :showLogo="false" @pressedGoHome="tryExit"></spot-header>
		<ion-content :fullscreen="true">
			<div id="fieldsContainer" class="content-rows" v-if="isActive">
				<div v-if="currField.id==0"> <!-- currField.name == 'employee' -->
					<spot-select-custom
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.employees"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						:custom-label="customEmployee"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addEmployee">
					</spot-select-custom>
				</div>
				<div v-if="currField.id==1"> <!-- currField.name == 'addressee' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.addressees"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addAddressee">
					</spot-select>
				</div>
				<div v-if="currField.id==2"> <!-- currField.name == 'carrier' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.carriers"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addCarrier">
					</spot-select>
				</div>
				<div v-if="currField.id==3"> <!-- currField.name == 'item' -->
					<spot-select
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:options="tablesData.items"
						:optionField="currField.refField"
						:canAddItem="currField.canAddItem"
						:canRefreshList="currField.canRefresh"
						:canUnlock="currField.canUnlock"
						selectedLabel="Selezionato"
						deselectLabel="rimuovi"
						selectLabel="seleziona"
						@selected="detectSelection"
						@unselected="detectUnselection"
						@addItem="addItem">
					</spot-select>
				</div>
				<div v-if="currField.id==4"> <!-- currField.name == 'pictures' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="items-data">
						<take-photo :enabled="!isClosed" :srcImage="currField.value" @saved="assignImage" @removed="clearImage" @cameraError="cameraNotFound"></take-photo>
						<!-- Example to browse photo on mobile and PC directories <input type="file" capture="camera" accept="image/*" id="cameraInput" name="cameraInput"> -->
						<ion-button fill="clear" :disabled="!currField.canAddItem" v-if="isVisibleButton(currField.canAddItem && !isClosed)" @click="addItem()">
							<ion-icon slot="icon-only" :icon="addCircleOutline" size="large"></ion-icon>
						</ion-button>
					</div>
				</div>
				<div v-if="currField.id==5"> <!-- currField.name == 'waiting_for_proforma' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="items-data">
						<ion-checkbox slot="start" v-model="currField.value" :placeholder="getPlaceHolder()" :disabled="isClosed"></ion-checkbox>
						<ion-button fill="clear" :disabled="!currField.canAddItem" v-if="isVisibleButton(currField.canAddItem) && !isClosed" @click="addItem()">
							<ion-icon slot="icon-only" :icon="addCircleOutline" size="large"></ion-icon>
						</ion-button>
					</div>
				</div>
				<div v-if="currField.id==6"> <!-- currField.name == 'urgent' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="items-data">
						<ion-checkbox slot="start" v-model="currField.value" :placeholder="getPlaceHolder()" :disabled="isClosed"></ion-checkbox>
						<ion-button fill="clear" :disabled="!currField.canAddItem" v-if="isVisibleButton(currField.canAddItem) && !isClosed" @click="addItem()">
							<ion-icon slot="icon-only" :icon="addCircleOutline" size="large"></ion-icon>
						</ion-button>
					</div>
				</div>
				<div v-if="currField.id==7"> <!-- currField.name == 'note' -->
					<spot-input
						:ref="currField.name"
						:label="currField.text"
						:allignLabel="currField.allignLabel"
						:required="currField.required"
						:placeholder="getPlaceHolder()"
						:enabled="!isClosed"
						:value="currField.value"
						:type="currField.inputType"
						:clearInput="true"
						:canUnlock="currField.canUnlock"
						@textChanged="changedInput"
						@committed="committedInput">
					</spot-input>
				</div>
				<div v-if="currField.id==8"> <!-- currField.name == 'review' -->
					<div class="items-label">
						<ion-label>{{ currField.text }}</ion-label>
						<span v-if="currField.required">*</span>
					</div>
					<div class="review-items">
						<ion-item lines="full" @click="goToField(fields.list[0].id)"> <!-- currField.name == 'employee' -->
							<ion-label>{{ fields.list[0].text }}:</ion-label>
							<!--<ion-input v-if="(fields.list[0].value)" type="text" readonly :value="fields.list[0].value.name + ' ' + fields.list[0].value.surname + (!fields.list[0].value.department ? '' : ' (' + fields.list[0].value.department.name + ')')"></ion-input>-->
							<ion-input v-if="(fields.list[0].value)" type="text" readonly :value="getEmployeeDepartment(fields.list[0].value)"></ion-input>
						</ion-item>
						<ion-item lines="full" @click="goToField(fields.list[1].id)"> <!-- currField.name == 'addressee' -->
							<ion-label>{{ fields.list[1].text }}:</ion-label>
							<ion-input v-if="(fields.list[1].value)" type="text" readonly v-model="fields.list[1].value.name"></ion-input>
						</ion-item>
						<ion-item lines="full" @click="goToField(fields.list[2].id)"> <!-- currField.name == 'carrier' -->
							<ion-label>{{ fields.list[2].text }}:</ion-label>
							<ion-input v-if="(fields.list[2].value)" type="text" readonly v-model="fields.list[2].value.name"></ion-input>
						</ion-item>
						<ion-item lines="full" @click="goToField(fields.list[3].id)"> <!-- currField.name == 'item' -->
							<ion-label>{{ fields.list[3].text }}:</ion-label>
							<ion-input v-if="(fields.list[3].value)" type="text" readonly v-model="fields.list[3].value.code"></ion-input>
						</ion-item>
						<ion-item slot="start" lines="full" :disabled="currField.value" @click="goToField(fields.list[4].id)"> <!-- currField.name == 'pictures' -->
							<ion-label position="fixed">{{ fields.list[4].text }}:</ion-label>
							<ion-thumbnail v-if="(fields.list[4].value)"><img :src="getImageUrl" /></ion-thumbnail>
						</ion-item>
						<ion-item lines="full" @click="goToField(fields.list[5].id)"> <!-- currField.name == 'waiting_for_proforma' -->
							<ion-label>{{ fields.list[5].text }}:</ion-label>
							<!--<ion-checkbox slot="end" v-model="fields.list[5].value" :disabled="true"></ion-checkbox>-->
							<ion-input type="text" readonly :value="(fields.list[5].value) ? 'Si' : 'No'"></ion-input>
						</ion-item>
						<ion-item lines="full" @click="goToField(fields.list[6].id)"> <!-- currField.name == 'urgent' -->
							<ion-label>{{ fields.list[6].text }}:</ion-label>
							<!--<ion-checkbox slot="end" v-model="fields.list[6].value" :disabled="true"></ion-checkbox>-->
							<ion-input type="text" readonly :value="(fields.list[6].value) ? 'Si' : 'No'"></ion-input>
						</ion-item>
						<ion-item lines="full" @click="goToField(fields.list[7].id)"> <!-- currField.name == 'note' -->
							<ion-label>{{ fields.list[7].text }}:</ion-label>
							<ion-input v-if="(fields.list[7].value)" type="text" readonly v-model="fields.list[7].value"></ion-input>
						</ion-item>
					</div>
				</div>
			</div>
			<div id="actionsContainer">
				<spot-button
					v-if="isVisibleButton(currField.canGoBack)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronBackCircleOutline"
					:enabled="currField.canGoBack && !fields.list[this.fields.list.length-1].value"
					:expand="null"
					:text=null
					@clicked="goBack()">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canSkip)"
					fill="clear"
					size="large"
					color="primary"
					icon="playSkipForwardCircleOutline"
					:enabled="!isEmptyRequiredFields()"
					:expand="null"
					:text=null
					@clicked="goToReview()">
				</spot-button>
				<ion-button
					class="multi-icons-button"
					v-if="isVisibleButton(currField.canSave && !isClosed)"
					:fill= "(showActionButtonsText) ? 'outline' : 'clear'"
					size="large"
					color="primary"
					:disabled="isEmptyRequiredFields()"
					@click="createAndPrint()">
					{{ (showActionButtonsText && !currField.value) ? (canPrint && !this.$route.params.id ? labelSave + labelAnd + labelPrint : labelSave) : (showActionButtonsText && canPrint && !this.$route.params.id ? labelReprint : null) }}
					<ion-icon v-if="!currField.value" slot="icon-only" :icon="save" size="large"></ion-icon>
					<ion-icon v-if="canPrint  && !this.$route.params.id" slot="icon-only" :icon="print" size="large"></ion-icon>
				</ion-button>
				<spot-button
					v-if="isVisibleButton(currField.canSave && !isClosed && multiple && currField.value)"
					:fill= "(showActionButtonsText) ? 'outline' : 'clear'"
					size="large"
					color="primary"
					icon="personCircleOutline"
					:enabled="!isEmptyRequiredFields()"
					:expand="null"
					:text="(showActionButtonsText) ? labelEmployee : null"
					@clicked="executeAction('NewEmployee')">
				</spot-button>
				<spot-button
				v-if="isVisibleButton(currField.canSave && !isClosed && multiple && currField.value)"
					:fill= "(showActionButtonsText) ? 'outline' : 'clear'"
					size="large"
					color="primary"
					icon="trailSign"
					:enabled="!isEmptyRequiredFields()"
					:expand="null"
					:text="(showActionButtonsText) ? labelAddressee : null"
					@clicked="executeAction('NewAddressee')">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canSave && !isClosed && currField.value)"
					:fill= "(showActionButtonsText) ? 'outline' : 'clear'"
					size="large"
					color="primary"
					icon="exit"
					:enabled="!isEmptyRequiredFields()"
					:expand="null"
					:text="(showActionButtonsText) ? labelExit : null"
					@clicked="executeAction('Exit')">
				</spot-button>
				<spot-button
					v-if="isVisibleButton(currField.canGoAhead)"
					fill="clear"
					size="large"
					color="primary"
					icon="chevronForwardCircleOutline"
					:enabled="!isDisableButton(currField.canGoAhead)"
					:expand="null"
					:text=null
					@clicked="goAhead()">
				</spot-button>
			</div>
			<div id="debugArea" style="color:red;" v-if="settings.debug">
				<div>Test Area</div>
				Current Field: {{ currField }}<br>
				<div v-for="field in fields.list" :key="field.id">
					{{ field.name }} = {{ field.value }}
				</div>
			</div>
		</ion-content>
	</ion-page>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style> <!--<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>-->
<style scoped>
	ion-label {
		font-weight:bold;
	}
	.content-rows {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		/*justify-content: space-around;*/
		/*align-content: flex-start;*/
		/*padding: 1em;*/
		/*height: 10%;*/
		/*overflow: auto;*/
	}
	.items-label {
		margin-top: .5em;
		margin-left: 1em;
	}
	.items-data {
		display: flex; /*inline-block;*/
		flex-direction: row;
		vertical-align: baseline;
		justify-content: space-between;
		text-align: left;
		margin-left: .5em;
		margin-right: .5em;
	}
	ion-checkbox {
		margin-top: .5em;
		margin-left: .5em;
	}
	.multi-icons-button {
		margin-top: .5em;
		margin-left: .25em;
		margin-right: .25em;
		font-size: 20px;
		--padding-start: 2vw;
		--padding-end: 2vw;
	}
	.multi-icons-button ion-icon {
		font-size: 20px;
		--padding-start: 2vw;
		--padding-end: 2vw;
	}
	.review-items ion-item {
		--min-height: 0px;
	}
	.review-items ion-input {
		--padding-top: .5vh;
		--padding-bottom: .5vh;
	}
	.review-items :is(ion-label, ion-thumbnail) {
		margin-top: .5vh;
		margin-bottom: .5vh;
	}
</style>

<script> //TODO: Da capire dove mettere il canTakePhoto (globale o a livello di campo) e i can<Other> (se servono)
import { IonButton, IonCheckbox, IonContent, IonIcon, IonInput, IonItem, IonLabel, IonPage, IonThumbnail } from "@ionic/vue";
import { addCircleOutline, chevronBackCircleOutline, chevronForwardCircleOutline, exit, personCircleOutline, playSkipForwardCircleOutline, print, save, trailSign } from "ionicons/icons";
import { defineComponent } from "vue";
import SpotButton from "../components/SpotButton.vue";
import SpotHeader from "../components/SpotHeader.vue";
import SpotInput from "../components/SpotInput.vue";
import SpotSelect from "../components/SpotSelect.vue";
import SpotSelectCustom from "../components/SpotSelectCustom.vue";
import TakePhoto from "../components/TakePhoto.vue";
import { checkUserSession, confirmYesNo, getUserInfo, insertValue, settings, showMsgError, showMsgInfo, showMsgWarning } from "../plugins/common.js";
import { backendUrl } from "../plugins/variables.js";

	export default defineComponent({
		name: "InsertShipment",
		props: {
			pageTitle: { type: String, default: "Spedizione" },
			insertActivity: { type: String, default: "Inserisci " },
			modifyActivity: { type: String, default: "Modifica " },
			viewActivity: { type: String, default: "Visualizza " },
			labelPrint: { type: String, default: "Stampa" },
			labelReprint: { type: String, default: "Ristampa" },
			labelSave: { type: String, default: "Salva" },
			labelAnd: { type: String, default: " e " },
			labelEmployee: { type: String, default: "Nuovo Mittente" }, //"Salva e Inizia da Mittente"
			labelAddressee: { type: String, default: "Nuovo Destinatario" }, //"Salva e Inizia da Destinatario"
			labelExit: { type: String, default: "Termina" },
			exitConfirmTitle: { type: String, default: "Conferma Uscita" },
			exitInsertConfirmMessage: { type: String, default: "Confermi l'uscita dalla maschera di inserimento? <br> Premendo SI il record che si sta inserendo andrà perso!" },
			exitModifyConfirmMessage: { type: String, default: "Confermi l'uscita dalla maschera di modifica? <br> Premendo SI, eventuali modifiche non saranno salvate!" },
			newValueTitle: { type: String, default: "Inserimento Nuovo Valore" },
			messageTitleInsert: { type: String, default: "Inserimento Nuova Spedizione" },
			messageTitleUpdate: { type: String, default: "Modifca Spedizione Esistente" },
			messageTitlePrint: { type: String, default: "Stampa Etichetta" },
			messageTitleCamera: { type: String, default: "Fotocamera" },
			successSaveMessage: { type: String, default: "Record salvato correttamente" },
			errorSaveMessage: { type: String, default: "Errore durante il salvataggio del record" },
			successMailMessage: { type: String, default: "E-mail inviata a: " },
			errorMailMessage: { type: String, default: "Errore durante l'invio della mail" },
			successPrintMessage: { type: String, default: "Stampa etichetta avvenuta correttamente. Etichetta: " },
			errorPrintMessage: { type: String, default: "Errore durante la stampa dell'etichetta" },
			errorPrintConfirmTitle: { type: String, default: "Conferma Avanzamento" },
			errorPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se la stampa non è avvenuta (l'etichetta non potrà essere stampata successivamente)?" },
			warningPrinterOffline: { type: String, default: "La stampante non è raggiungibile; l'etichetta non è stata stampata" },
			warningPrintConfirmMessage: { type: String, default: "Confermi di procedere lo stesso anche se non è stata assegnata una stampante (l'etichetta non verrà stampata)?" },
			warningCameraNotFound: { type: String, default: "Camera del dispositivo non presente o non utilizzabile!" },
			unselected: { type: String, default: "Nessuno" },
		},
		components: {
			IonPage,
			IonItem,
			IonButton,
			IonIcon,
			IonContent,
			IonLabel,
			IonInput,
			IonCheckbox,
			IonThumbnail,
			SpotHeader,
			SpotButton,
			SpotInput,
			SpotSelect,
			SpotSelectCustom,
			TakePhoto,
		},
		data: () => ({
			isActive: null,
			isClosed: null,
			header_id: null,
			header_code: null,
			multipack: null,
			multiple: null,
			plant_id: null,
			printer_id: null,
			barcode: null,
			sender: null,
			fields: {
				currentId: 0,
				list: [ //TODO: Nota che, in realtà canSkip, è poi uguale a NOT required (forse può essere rimosso), forse solo nel riepilogo è diverso, ma ci si può guardare
					{ id: 0, name: 'employee', text: 'Richiedente', allignLabel: false, placeholder: 'Seleziona Dipendente', refTable: 'employees', inputType: "combo", defaultValue: null/*1*/, value: null, required: true, refField: 'id', canAddItem: false, canSkip: true, canGoBack: false, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 1, name: 'addressee', text: 'Destinatario', allignLabel: false, placeholder: 'Seleziona Destinatario', refTable: 'addressees', inputType: "combo", defaultValue: null, value: null, required: true, refField: 'name', canAddItem: true, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 2, name: 'carrier', text: 'Corriere', allignLabel: false, placeholder: 'Seleziona Corriere', refTable: 'carriers', inputType: "combo", defaultValue: null, value: null, required: true, refField: 'name', canAddItem: true, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 3, name: 'item', text: 'Formato', allignLabel: false, placeholder: 'Seleziona Formato Spedizione', refTable: 'items', inputType: "combo", defaultValue: null, value: null, required: true, refField: 'code', canAddItem: true, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 4, name: 'pictures', text: 'Immagine', allignLabel: false, placeholder: 'Aggiungi Foto', refTable: null, inputType: "button", defaultValue: null, value: null, required: false, refField: null, canAddItem: false/*true*/, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 5, name: 'waiting_for_proforma', text: 'Attendi Pro forma', allignLabel: false, placeholder: '', refTable: null, inputType: "check", defaultValue: null/*false*/, value: null/*false*/, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 6, name: 'urgent', text: 'Urgente', allignLabel: false, placeholder: '', refTable: null, inputType: "check", defaultValue: null/*false*/, value: null/*false*/, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 7, name: 'note', text: 'Note', allignLabel: false, placeholder: 'Inserisci Note', refTable: null, inputType: "text", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: true, canGoBack: true, canGoAhead: true, canSave: false, canRefresh: false, canUnlock: false },
					{ id: 8, name: 'review', text: 'RIEPILOGO', allignLabel: false, placeholder: '', refTable: null, inputType: "readOnlyTexts", defaultValue: null, value: null, required: false, refField: null, canAddItem: false, canSkip: false, canGoBack: true, canGoAhead: false, canSave: true, canRefresh: false, canUnlock: false },
				],
			},
			tablesData: {
				employees: [],
				addressees: [],
				carriers: [],
				items: [],
				project: null,
			},
			showActionButtonsText : true,
			hasChangedPhoto : false,
			chevronBackCircleOutline,
			chevronForwardCircleOutline,
			addCircleOutline,
			trailSign,
			personCircleOutline,
			exit,
			save,
			print,
			playSkipForwardCircleOutline,
			settings,
			getUserInfo,
			confirmYesNo,
			checkUserSession,
			insertValue,
			showMsgInfo,
			showMsgWarning,
			showMsgError,
			backendUrl,
		}),
		computed: {
			currField() {
				return this.fields.list[this.fields.currentId]
			},
			canPrint() {
				return (this.settings.printerManagement && (this.printer_id != null))
			},
			recActivity() {
				if (this.$route.params.id && this.isClosed) return this.viewActivity
				else if (this.$route.params.id && !this.isClosed) return this.modifyActivity
				else if (!this.$route.params.id) return this.insertActivity
				else return ""
			},
			getImageUrl() {
				if (typeof this.fields.list[4].value == 'string')
					return this.fields.list[4].value
				else
					return window.URL.createObjectURL(this.fields.list[4].value)
			}
		},
		async ionViewWillEnter() {
			await this.setDefault();
			this.getAllTablesData();
			this.getPrinter();
			await this.getCurrentRec();
			await this.getCurrentProject();
			this.isActive=true;
		},
		ionViewWillLeave() {
			this.isActive=false;
		},
		methods: {
			//Settings
			tryExit() {
				if (!this.isClosed && !this.fields.list[this.fields.list.length-1].value) {
					this.confirmYesNo(this.exitConfirmTitle, (this.$route.params.id ? this.exitModifyConfirmMessage : this.exitInsertConfirmMessage))
					.then((data) => {
						if (data == true) {
							if (!this.multipack) this.$router.push("/home");
							else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
						}
					})
				}
				else {
					if (!this.multipack) this.$router.push("/home");
					else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
				}
			},
			getPlaceHolder() {
				return ((this.settings.showFieldsPlaceholder) ? (this.currField.required ? this.currField.placeholder : this.unselected ) : '')
			},
			isDisableButton(ability) {
				if (this.settings.fieldsCheckOnButtons /*&& ability*/) {
					return (this.currField.required && !this.currField.value) ?  true : false
				}
				else {
					return !ability
				}
			},
			isEmptyRequiredFields() {
				let ret = false;
				this.fields.list.forEach(element => {if (element.required && !element.value) ret=true});
				return ret;
			},
			isVisibleButton(ability) {
				return (this.settings.hideUnneededButtons) ? ability : true
			},
			customEmployee ({ name, surname, department, seat, seat_code }) {
				let infoDepartment = "";
				if (department)
					infoDepartment = " (" + department.name + ")"
				else if (seat)
					infoDepartment = " (" + seat + ")"
				else if (seat_code)
					infoDepartment = " (" + seat_code + ")"
				return name + " " + surname + infoDepartment
			},
			getEmployeeDepartment(srcObject) {
				let infoDepartment = "";
				if (srcObject.department)
					infoDepartment = " (" + srcObject.department.name + ")"
				else if (srcObject.seat)
					infoDepartment = " (" + srcObject.seat + ")"
				else if (srcObject.seat_code)
					infoDepartment = " (" + srcObject.seat_code + ")"
				return srcObject.name + " " + srcObject.surname + infoDepartment
			},
			//Database
			async searchData(tableName, filters) {
				let data = await this.$store.dispatch("tables/querySearch", { model: tableName, data: filters })
				return data;
			},
			async getData(tableName) {
				let data = await this.$store.dispatch("tables/fetch", { model: tableName })
				return data;
			},
			async readData(tableName, recordId) {
				let data = await this.$store.dispatch("tables/read", { model: tableName, id: recordId })
				return data;
			},
			async updateData(tableName, recordId, record) {
				let data = await this.$store.dispatch("tables/update", { model: tableName, id: recordId, data: record })
				return data;
			},
			async updateFormData(tableName, recordId, record) {
				let data = await this.$store.dispatch("tables/updateForm", { model: tableName, id: recordId, data: record })
				return data;
			},
			async writeData(tableName, record) {
				let data = await this.$store.dispatch("tables/create", { model: tableName, data: record })
				return data;
			},
			async writeFormData(tableName, record) {
				let data = await this.$store.dispatch("tables/createForm", { model: tableName, data: record })
				return data;
			},
			getAllTablesData() {
				this.getEmployees();
				this.getAddressees();
				this.getCarriers();
				this.getItems();
			},
			getEmployees() {
				this.getData("employees")
				.then(data => { this.tablesData.employees = data; })
				.catch(error => { this.tablesData.employees = []; this.checkUserSession(error, "warning"); });
			},
			getAddressees() {
				this.getData("addressees")
				.then(data => { this.tablesData.addressees = data; })
				.catch(error => { this.tablesData.addressees = []; this.checkUserSession(error, "warning"); });
			},
			getCarriers() {
				this.getData("carriers")
				.then(data => { this.tablesData.carriers = data; })
				.catch(error => { this.tablesData.carriers = []; this.checkUserSession(error, "warning"); });
			},
			getItems() {
				this.getData("items")
				.then(data => { this.tablesData.items = data; })
				.catch(error => { this.tablesData.items = []; this.checkUserSession(error, "warning"); });
			},
			getPrinter() {
				if (this.settings.printerManagement) {
					let userInfo = this.getUserInfo();
					if (!userInfo || !userInfo.plant || !userInfo.plant.printer_id) {
						let currSearch = {
							params: {
								'q[default_eq]': true,
							}
						}
						this.searchData("printers", currSearch)
						.then(data => {
							if (data.length>0) {
								this.printer_id = data[0].id
							}
							else {
								this.printer_id = null;
								this.confirmYesNo(this.messageTitlePrint, this.warningPrintConfirmMessage)
								.then((data) => {
									if (data == false) {
										if (!this.multipack) this.$router.push("/home");
										else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
									}
								})
							}
						})
						.catch(error => { this.printer_id = null; this.checkUserSession(error, "warning"); });
					}
					else {
						this.printer_id = userInfo.plant.printer_id;
					}
				}
				else {
						this.printer_id = null;
				}
			},
			prepareNewRecord() {
				let myRecord = {
					project_row: {
						project_id: this.header_id,
						employee_id: (this.fields.list[0].value) ? this.fields.list[0].value.id : null,
						addressee_id: (this.fields.list[1].value) ? this.fields.list[1].value.id : null,
						carrier_id: (this.fields.list[2].value) ? this.fields.list[2].value.id : null,						
						item_id: (this.fields.list[3].value) ? this.fields.list[3].value.id : null,
						waiting_for_proforma: (this.fields.list[5].value) ? this.fields.list[5].value : false,
						urgent: (this.fields.list[6].value) ? this.fields.list[6].value : false,
						notes: (this.fields.list[7].value) ? this.fields.list[7].value : null,
						plant_id: this.plant_id,
					}
				};
				return myRecord;
			},
			prepareNewFormRecord() {
				let myRecord = new FormData();
				
				myRecord.append('project_rows[project_id]', this.header_id);
				if (this.fields.list[0].value) myRecord.append('project_rows[employee_id]', this.fields.list[0].value.id);
				if (this.fields.list[1].value) myRecord.append('project_rows[addressee_id]', this.fields.list[1].value.id);
				if (this.fields.list[2].value) myRecord.append('project_rows[carrier_id]', this.fields.list[2].value.id);
				if (this.fields.list[3].value) myRecord.append('project_rows[item_id]', this.fields.list[3].value.id);
				myRecord.append('project_rows[pictures][]', this.fields.list[4].value);
				myRecord.append('project_rows[waiting_for_proforma]', (this.fields.list[5].value) ? this.fields.list[5].value : false);
				myRecord.append('project_rows[urgent]', (this.fields.list[6].value) ? this.fields.list[6].value : false);
				if (this.fields.list[7].value) myRecord.append('project_rows[notes]', this.fields.list[7].value);
				myRecord.append('project_rows[plant_id]', this.plant_id);
				return myRecord;
			},
			prepareUpdateRecord() {
				let myRecord = {
					project_row: {
						employee_id: (this.fields.list[0].value) ? this.fields.list[0].value.id : null,
						addressee_id: (this.fields.list[1].value) ? this.fields.list[1].value.id : null,
						carrier_id: (this.fields.list[2].value) ? this.fields.list[2].value.id : null,
						item_id: (this.fields.list[3].value) ? this.fields.list[3].value.id : null,
						pictures: (this.fields.list[4].value) ? this.fields.list[4].value.id : null,
						waiting_for_proforma: (this.fields.list[5].value) ? this.fields.list[5].value : false,
						urgent: (this.fields.list[6].value) ? this.fields.list[6].value : false,
						notes: (this.fields.list[7].value) ? this.fields.list[7].value : null,
						plant_id: this.plant_id,
					}
				};
				return myRecord;
			},
			prepareUpdateFormRecord() {
				let myRecord = new FormData();
				
				if (this.fields.list[0].value) myRecord.append('project_rows[employee_id]', this.fields.list[0].value.id);
				if (this.fields.list[1].value) myRecord.append('project_rows[addressee_id]', this.fields.list[1].value.id);
				if (this.fields.list[2].value) myRecord.append('project_rows[carrier_id]', this.fields.list[2].value.id);
				if (this.fields.list[3].value) myRecord.append('project_rows[item_id]', this.fields.list[3].value.id);
				if (this.hasChangedPhoto) myRecord.append('project_rows[pictures][]', this.fields.list[4].value);
				myRecord.append('project_rows[waiting_for_proforma]', (this.fields.list[5].value) ? this.fields.list[5].value : false);
				myRecord.append('project_rows[urgent]', (this.fields.list[6].value) ? this.fields.list[6].value : false);
				if (this.fields.list[7].value) myRecord.append('project_rows[notes]', this.fields.list[7].value); else myRecord.append('project_rows[notes]', '');
				myRecord.append('project_rows[plant_id]', this.plant_id);
				return myRecord;
			},
			saveRecord() {
				if (!this.fields.list[4].value) this.saveRecordJson();
				else this.saveRecordFormData();
			},
			saveRecordJson() {
				this.writeData("project_rows", this.prepareNewRecord())
				.then(data => {
					this.barcode = data.code;
					let currEmployee = "";
					if (data.employee) {
						this.sender = "<br><br>" + this.successMailMessage + "&nbsp" + data.employee.name + "&nbsp" + data.employee.surname;
						currEmployee = data.employee.name + ` ` + data.employee.surname;
					} else {
						this.sender = ""/*null*/;
					}
					this.currField.value=true;
					if (this.canPrint && this.printer_id != null) {
						this.readData("printers", this.printer_id)
						.then(data => {
							if (data.is_online == true) {
								this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.barcode + `&direction=MITTENTE:` + `&employee=` + currEmployee)
								.then(() => {
									this.showMsgInfo(
										this.messageTitleInsert,
										this.successSaveMessage + this.sender + "<br><br>" + this.successPrintMessage + "&nbsp" + this.barcode);
								})
								.catch(error => {
									this.checkUserSession(error, "warning");
									this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
								})
							}
							else {
								this.showMsgWarning(
										this.messageTitleInsert,
										this.successSaveMessage + this.sender + "<br><br>" + this.warningPrinterOffline);
							}
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgInfo(
							this.messageTitleInsert,
							this.successSaveMessage + this.sender);
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
				})
			},
			saveRecordFormData() {
				this.writeFormData("project_rows", this.prepareNewFormRecord())
				.then(data => {
					this.barcode = data.code;
					let currEmployee = "";
					if (data.employee) {
						this.sender = "<br><br>" + this.successMailMessage + "&nbsp" + data.employee.name + "&nbsp" + data.employee.surname;
						currEmployee = data.employee.name + ` ` + data.employee.surname;
					} else {
						this.sender = ""/*null*/;
					}
					this.currField.value=true;
					if (this.canPrint && this.printer_id != null) {
						this.readData("printers", this.printer_id)
						.then(data => {
							if (data.is_online == true) {
								this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.barcode + `&direction=MITTENTE:` + `&employee=` + currEmployee)
								.then(() => {
									this.showMsgInfo(
										this.messageTitleInsert,
										this.successSaveMessage + this.sender + "<br><br>" + this.successPrintMessage + "&nbsp" + this.barcode);
								})
								.catch(error => {
									this.checkUserSession(error, "warning");
									this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
								})
							}
							else {
								this.showMsgWarning(
										this.messageTitleInsert,
										this.successSaveMessage + this.sender + "<br><br>" + this.warningPrinterOffline);
							}
						})
						.catch(error => {
							this.checkUserSession(error, "warning");
							this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
						})
					}
					else {
						this.showMsgInfo(
							this.messageTitleInsert,
							this.successSaveMessage + this.sender);
					}
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
				})
			},
			updateRecord() {
				if (!this.fields.list[4].value) this.updateRecordJson();
				else this.updateRecordFormData();
			},
			updateRecordJson() {
				this.updateData("project_rows", this.$route.params.id, this.prepareUpdateRecord())
				.then(()/*data*/ => {
					this.showMsgInfo(
						this.messageTitleUpdate,
						this.successSaveMessage /*+ "<br><br>" + this.successMailMessage + "&nbsp" + data.employee.name + "&nbsp" + data.employee.surname*/);
					if (!this.multipack) this.$router.push("/home");
					else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleUpdate, this.errorSaveMessage);
				})
			},
			updateRecordFormData() {
				this.updateFormData("project_rows", this.$route.params.id, this.prepareUpdateFormRecord())
				.then(()/*data*/ => {
					this.showMsgInfo(
						this.messageTitleUpdate,
						this.successSaveMessage /*+ "<br><br>" + this.successMailMessage + "&nbsp" + data.employee.name + "&nbsp" + data.employee.surname*/);
					if (!this.multipack) this.$router.push("/home");
					else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
				})
				.catch(error => {
					this.checkUserSession(error, "warning");
					this.showMsgError(this.messageTitleUpdate, this.errorSaveMessage);
				})
			},
			async getCurrentRec() {
				if (this.$route.params.id) {
					await this.readData("project_rows", this.$route.params.id)
					.then(data => {
						this.currentRec = data;
						this.fields.list[0].value=(this.currentRec.employee) ? this.currentRec.employee : null;
						this.fields.list[1].value=(this.currentRec.addressee) ? this.currentRec.addressee : null;
						this.fields.list[2].value=(this.currentRec.carrier) ? this.currentRec.carrier : null;
						this.fields.list[3].value=(this.currentRec.item) ? this.currentRec.item : null;
						this.fields.list[4].value=(this.currentRec.picture_urls) ? this.backendUrl + this.currentRec.picture_urls : null;
						this.fields.list[5].value=(this.currentRec.waiting_for_proforma) ? this.currentRec.waiting_for_proforma : false;
						this.fields.list[6].value=(this.currentRec.urgent) ? this.currentRec.urgent : false;
						this.fields.list[7].value=(this.currentRec.notes && this.currentRec.notes.body) ? this.currentRec.notes.body : null;
						this.isClosed=(this.currentRec.closed) ? true : false;
						this.header_id=this.currentRec.project.id;
						this.header_code=this.currentRec.project.code;
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
					})
				}
				if (this.$route.query.header_id) this.header_id = parseInt(this.$route.query.header_id);
				if (this.$route.query.multiple) this.multiple = (this.$route.query.multiple === 'true');
				if (this.$route.query.multipack) this.multipack = (this.$route.query.multipack === 'true');
			},
			async getCurrentProject() {
				if (this.$route.query.header_id) {
					await this.readData("projects", this.header_id)
					.then(data => {
						this.tablesData.project = data;
						this.header_code = this.tablesData.project.code;
						if (this.multipack) {
							this.fields.list[0].value=(this.tablesData.project.employee) ? this.tablesData.project.employee : null;
						}
					})
					.catch(error => {
						this.checkUserSession(error, "warning");
					})
				}
			},
			//Actions
			createAndPrint() {
				if (!this.$route.params.id) {
					if (!this.currField.value) {
						this.saveRecord();
					}
					else {
						let currEmployee = (this.fields.list[0].value) ? this.fields.list[0].value.name + ` ` + this.fields.list[0].value.surname : ""/*null*/;
						if (this.canPrint && this.printer_id != null) {
							this.readData("printers", this.printer_id)
							.then(data => {
								if (data.is_online == true) {
									this.getData(`printers/` + this.printer_id + `?do=print_single_barcode` + `&barcode=` + this.barcode + `&direction=MITTENTE:` + `&employee=` + currEmployee)
									.then(() => {
										this.showMsgInfo(
											this.messageTitlePrint,
											this.successPrintMessage + "&nbsp" + this.barcode);
									})
									.catch(error => {
										this.checkUserSession(error, "warning");
										this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
									})
								}
								else {
									this.showMsgWarning(
										this.messageTitlePrint,
										this.warningPrinterOffline);
								}
							})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitlePrint, this.errorPrintMessage);
							})
						}
					}
				}
				else {
					this.updateRecord();
				}
			},
			executeAction(action) {
				if (!this.$route.params.id) {
					switch (action) {
						case 'NewEmployee':
							this.fields.currentId = 0;
							this.fields.list.forEach(element => {if (element.id > 0) element.value = element.defaultValue;});
							break;
						case 'NewAddressee':
							this.fields.currentId = 1;
							this.fields.list.forEach(element => {if (element.id > 1) element.value = element.defaultValue;});
							break;
						case 'Exit':
							if (!this.multipack) this.$router.push("/home");
							else this.$router.push(`/revision/` + this.header_id + `?code=` + this.header_code);
							break;
						default:
							//TODO: aggiungi checkUserSession (ma non dovrebbe mai arrivarci)
					}
				}
			},
			async setDefault() {
				await this.getData("printers")
				.then(data => { (data.length > 0) ? this.settings.printerManagement = true : this.settings.printerManagement = false ; })
				.catch(error => { this.checkUserSession(error, "warning"); });
				this.fields.currentId = 0;
				this.fields.list.forEach(element => element.value = element.defaultValue);
				this.isClosed = false;
				this.multiple = false;
				this.multipack = false;
				this.header_id = null;
				this.header_code = null;
				this.plant_id = this.getUserInfo().plant_id;
				this.hasChangedPhoto = false;
			},
			detectReturn(keyCode) {
				if (keyCode == 13) setTimeout(() => this.goAhead(), 0);
			},
			changedInput(value) {
				this.currField.value = value;
			},
			committedInput() {
				setTimeout(() => this.goAhead(), 0);
			},
			detectSelection(value) {
				this.currField.value = value;
				if (this.settings.nextFieldOnComboSelect) setTimeout(() => this.goAhead(), 0);
			},
			detectUnselection() {
				this.currField.value = this.currField.defaultValue;
			},
			goBack() {
				if (this.fields.currentId >= 0 ) this.fields.currentId-=1;
			},
			goAhead() {
				if (this.fields.currentId <= (this.fields.list.length-1) ) this.fields.currentId+=1
				else this.executeLastFieldAction()
			},
			goToReview() {
				if (this.fields.currentId >= 0 && this.fields.currentId <= (this.fields.list.length-1) ) this.fields.currentId=this.fields.list.length-1
			},
			goToField(targetFieldId) {
				if (targetFieldId >= 0 && targetFieldId <= (this.fields.list.length-1) ) this.fields.currentId=targetFieldId
			},
			assignImage(file) {
				this.hasChangedPhoto = true;
				this.currField.value = file;
				setTimeout(() => this.goAhead(), 0);
			},
			clearImage() {
				this.currField.value = null;
			},
			cameraNotFound() {
				this.showMsgWarning(
					this.messageTitleCamera,
					this.warningCameraNotFound);
			},
			executeLastFieldAction() {
				//TODO: Da Implementare (vedi quello della Insert, ma forse non serve perchè potrei abilitare solo il pulsante di salvataggio)
			},
			addEmployee() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								employees: {
									name: data,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getEmployees(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			addAddressee() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								addressees: {
									name: data,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getAddressees(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			addCarrier() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								carriers: {
									name: data,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getCarriers(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
			addItem() {
				this.insertValue(this.newValueTitle, this.currField.text)
					.then((data) => {
						if (data) {
							let myRecord = {
								items: {
									code: data,
									location_id: 1,
								}
							}
							this.writeData(this.currField.refTable, myRecord)
							.then((data) => { this.getItems(); this.currField.value = data})
							.catch(error => {
								this.checkUserSession(error, "warning");
								this.showMsgError(this.messageTitleInsert, this.errorSaveMessage);
							})
						}
					})
			},
		},
	});
</script>